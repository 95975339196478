/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'
import {Layout} from '../layout/layout'
import {StaticImage} from 'gatsby-plugin-image'
import EngineerIcon from '../graphics/engineer.svg'
import {IconSquare} from '../layout/icon-square'
import {TitledList} from '../layout/titled-list'
import {SectionBlack, SectionGray, SectionImage, SectionWhite} from '../layout/section'
// import cmmcVideo from '../videos/cmmcVideo.mp4'

const CMMC = () => (
  <Layout>
    <SectionImage image={(
      <StaticImage
        sx={{
          width: '100%',
          height: '100%',
        }}
        loading="eager"
        layout="fullWidth"
        src="../images/new-what-we-do.jpg"
        alt=""/>
    )}>
      <Themed.h1 sx={{
        color: 'white',
        marginBottom: ['2rem', '1rem'],
        maxWidth: '46rem',
        fontWeight: 'regular',
      }}>
        Achieve CMMC compliance with Google Workspace.
      </Themed.h1>

      <Box sx={{
        maxWidth: '42rem',
        fontWeight: 'regular',
      }}>
        ATX Defense is the first certified Google partner to exclusively focus on CMMC compliance for small businesses – including Duet AI, Google’s CUI-capable Generative AI tools.
      </Box>
    </SectionImage>
      <SectionBlack>
        <Themed.h3 sx={{
          // textAlign: ['center', 'initial'],
          // color: 'atxOrange',
          // fontWeight: 'bold',
          // mb: '0.5rem',
        }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          paddingTop: '56.25%',
          position: 'relative',
          iframe: {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '750px',
            height: '100%',
            maxHeight: '500px'
          }
          }}>
          <iframe width="750" height="500" src="https://www.youtube.com/embed/vHCtVe3KaF4?si=riUaiVqMWUtjR_tr" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </Box>
        </Themed.h3>
        </SectionBlack>  

    <SectionWhite>
      <Themed.h3 sx={{
        textAlign: ['center', 'initial'],
        color: 'atxOrange',
        fontWeight: 'bold',
        mb: '1rem',
      }}>
        We can help you achieve CMMC compliance with minimal impact to business operations.
      </Themed.h3>
      <Box sx={{fontWeight: 'medium', textAlign: ['center', 'initial']}}>
        Review our free CMMC 2.1 Level 1 Implementation Guide for Google Workspace, and reach out to start your CMMC journey today for as little as $20/user/month. For more information, <a href='https://cmmcguide.atxdefense.com' style={{color: '#316094'}}>click here</a>!
      </Box>
    </SectionWhite>


    <SectionGray>
      <Themed.h2 sx={{fontWeight: 'bold', mb: '2rem', textAlign: 'center'}}>
        OUR CMMC SERVICES
      </Themed.h2>

      <Box sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        gap: '2rem',
      }}>
        <ServiceLevel
          title="Free CMMC Consulting"
          items={[
            'Buy Google Workspace from us (price matched with Google) and get up to 5 hours of complimentary CMMC consulting.',
          ]}
          icon={<EngineerIcon/>}/>

        <ServiceLevel
          title="Gap Assessments"
          items={[
            'Understand where you need to focus to achieve CMMC compliance and receive a valid score for the DoD Supplier Performance Risk System (SPRS).',
          ]}
          icon={<EngineerIcon/>}/>

        <ServiceLevel
          title="Business Implementation"
          items={[
            'Close the gaps in a way that has minimal impact on business operations.',
          ]}
          icon={<EngineerIcon/>}/>

        <ServiceLevel
          title="Managed Services"
          items={[
            'ATX Defense can assume responsibility for ongoing CMMC compliance and Google Workspace administration, freeing you to run your business.',
          ]}
          icon={<EngineerIcon/>}/>
      </Box>
    </SectionGray>
  </Layout>
)

type ServiceLevelProps = {
  title: string
  items: string[]
  icon: React.ReactElement
}

const ServiceLevel = ({title, items, icon}: ServiceLevelProps) => (
  <Box sx={{
    background: 'linear-gradient(to bottom, white 0%, white 100%) no-repeat',
    backgroundSize: '100% calc(100% - 35px)',
    backgroundPosition: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 1rem 3rem 1rem',
    gap: '1rem',
  }}>
    <IconSquare icon={icon}/>
    <TitledList title={title} items={items}/>
  </Box>
)

export default CMMC
